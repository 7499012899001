import React, { useState, useEffect } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { clsx } from 'clsx';

import { Button } from '../components/atoms/button';
import { CloseButton } from '../components/atoms/close-button';
import { Header } from '../components/molecules/header';

import { Head } from '../components/templates/head';

import HomeIcon from '../assets/home.svg';

const IndexPage = () => {
	const [answered, setAnswered] = useState({});
	const [invalid, setInvalid] = useState(false);

	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	const question = {
		id: 1,
		question: 'What are you looking for today?',
		answers: [
			{ id: '1a', answer: 'I need advice', page: '/advice' },
			{
				id: '1b',
				answer: 'I need URGENT advice',
				page: '/urgent-support',
			},
			{
				id: '1c',
				answer: 'I am looking for long term support',
				page: '/longterm-support',
			},
		],
	};

	const questionClasses = clsx(
		'relative before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none mt-6',
		invalid ? 'before:bg-alert-400' : ''
	);

	useEffect(() => {
		const hasWindow = typeof window !== 'undefined';
		const hasGtag = typeof window.gtag === 'function';

		if (!hasWindow || !hasGtag) return;

		window.gtag('event', 'question_viewed', {
			question: question.question,
			answers: question.answers.map((answer) => answer.answer).join(', '),
			total_answers: question.answers.length,
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleAnswerClick = (answer) => {
		setInvalid(false);
		setAnswered(answer);
	};

	const handleNextClick = (answeredArg) => {
		if (!answeredArg.id) {
			return setInvalid(true);
		}

		const hasWindow = typeof window !== 'undefined';
		const hasGtag = typeof window.gtag === 'function';

		if (hasWindow && hasGtag) {
			window.gtag('event', 'question_answered', {
				question: question.question,
				answers: answeredArg.answer,
				total_answers: 1,
				question_type: 'primary',
			});
		}

		navigate(answeredArg.page);
	};

	return (
		<>
			<Head title={site.siteMetadata.title} />
			<main id="main" className="container px-4 py-7 md:py-0 sm:mx-auto">
				<div className="text-center md:pt-7">
					<div
						className="w-full max-w-full min-w-0 flow-vertical"
						style={{ '--space-vertical': 'var(--space-l-xl)' }}
					>
						<Header
							backBtn={
								<CloseButton
									id="alt"
									destination="https://www.peninsulagrouplimited.com/"
									ariaLabel="Go to the Peninsula homepage"
									className="!py-xxs-f !px-l-f"
								>
									<HomeIcon className="mr-2" />
									Peninsula
								</CloseButton>
							}
						/>
						<h1 className="text-2xl-f">
							Get the answers you need for your business, fast
						</h1>
						<ol className="wrapper-small">
							<li>
								<div
									className={clsx(
										questionClasses,
										'flow-vertical'
									)}
									style={{
										'--space-vertical': 'var(--space-m)',
									}}
								>
									<hgroup>
										<h2 className="text-xl font-centra-medium">
											{question.question}
										</h2>
										{invalid ? (
											<p
												className="mt-1 text-alert-500 font-centra-book question-invalid"
												role="alert"
											>
												This question is required
											</p>
										) : null}
									</hgroup>
									<ul className="flex flex-col gap-1 mb-6 ">
										{question.answers.map((answer) => {
											const isSelected =
												answered.id === answer.id;
											return (
												<li
													key={`intro-${'question'}-${
														question.id
													}-answer-${answer.id}`}
													className="flex-initial"
												>
													<Button
														variant={
															isSelected
																? 'AnswerSelected'
																: 'Answer'
														}
														className="px-6 answer"
														onClick={() =>
															handleAnswerClick(
																answer
															)
														}
													>
														{answer.answer}
													</Button>
												</li>
											);
										})}
									</ul>
								</div>
							</li>
						</ol>
						<div className="text-center">
							<Button
								variant="Main"
								className="!px-8"
								onClick={() => handleNextClick(answered)}
							>
								Next
							</Button>
							<p
								className="block w-full mt-2 invalid-message text-alert-500"
								aria-live="assertive"
							>
								{invalid ? 'Please fill in all questions' : ''}
							</p>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default IndexPage;
